import './style.css'
import { Info } from '../svg'
import { useState } from 'react'

export const ToAdmin = () => {
    const [openInfo, setOpenInfo] = useState(false)

    return (
        <div className='toAdminPage'>
            <div className='toAdmin'>
                <div className='adminCheck' >
                    <h1>Ваше объявление будет проверено модератором.</h1>
                    <div className='cursor' onClick={() => setOpenInfo(!openInfo)}>
                        <Info />
                    </div>
                </div>
                <p>После успешной проверки оно будет размещено на JustCode и мы вышлем подтверждающее письмо на адрес <span>user@gmail.com</span></p>
            </div>
            {openInfo && <div className='toAdminPage'>
                <h1>Модерация объявлений</h1>
                <div className='toAdmin'>
                    <p>Для того, чтобы объявления на List.am были более качественными и полезными, а также не нарушали наши правила, они проходят модерацию. Объявления могут проверяться модераторами перед подачей, а также после публикации, редактирования или в любое другое время.</p>
                    <h2>Как модератор проверяет объявление?</h2>
                    <ul>
                        <li>Проверяется правильность выбранного раздела</li>
                        <li>Проверяется заголовок и текст объявления</li>
                        <li>Проверяются все фотографии, чтобы убедиться, что они соответствуют действительности и не содержат ничего лишнего</li>
                        <li>Проверяется соответствие цены и других параметров</li>
                        <li>Убеждается, что объявление не относится к запрещенным товарам или услугам</li>
                        <li>Проверяется соответствие объявления и действий пользователя нашим правилам, пользовательскому соглашению и законам страны</li>
                    </ul>
                    <h2>Новые объявления</h2>
                    <p>Перед проверкой, новые объявления помещаются в папку "На модерации" на странице "Мои объявления" в вашем личном кабинете. <br /><br />При отклонении новых объявлений модератором, вам автоматически отправляется письмо с указанием причины отклонения. А также отклоненное объявление помещается в папку "Отклоненные" на странице "Мои объявления" в вашем личном кабинете, где вы можете исправить ошибки и подать объявление снова. Отклоненные объявления, оставленные в этой папке, удаляются навсегда через несколько недель.</p>
                    <h2>Существующие объявления</h2>
                    <p>Если модератор, после проверки существующего объявления находит, что оно нарушает наши правила, то такое объявление удаляется навсегда. При этом вам может быть заблокирована возможность размещения новых объявлений в связи с нарушениями наших правил.</p>
                    <h2>Моё оплаченное объявление отклонили или удалили. Что будет с деньгами?</h2>
                    <p>Объявления, нарушающие правила, удаляются независимо от того, применены к ним платные услуги или нет. Если объявление было заблокировано из-за нарушения правил, денежные средства не возвращаются.</p>
                    <h2>Мое объявление несправедливо отклонили</h2>
                    <p>Если вы считаете, что ваше объявление был отклонено необоснованно, напишите нам в службу поддержки и укажите заголовок объявления, которое было отклонено. <br /><br />Перед обращением, пожалуйста, внимательно прочитайте причину отказа и Правила публикации объявлений.</p>
                </div>
            </div>}
        </div>
    )
}