import store from "../store/auth_store"

export const Auth_reducer = (state = store, action) => {
    let temp = { ...state }
    switch (action.type) {
        case 'user':
            temp.user = action.payload
            break;
        case 'openMenu':
            temp.openCategories = true
            break
        case 'closeMenu':
            temp.openCategories = false
            break
        case 'openMask':
            temp.openMask = true
            break;
        case 'closeMask':
            temp.openMask = false
            break;
        case 'loginError':
            temp.loginError = true
            break;
        case 'loginErrorFixed':
            temp.loginError = false
            break;
        default:
            return temp;
    }
    return temp;
}